import service from "@/api/service";
import { RegisterRequest, SearchRequest } from "@/api/point/request";
import { SearchResponse } from "@/api/point/response";
import { CommonResponse } from "@/api/response";

/**
 * ポイント一覧画面APIをコールします。
 *
 * @param searchRequest 検索のリクエストボディ
 * @return SearchResponse
 */
export async function search(searchRequest: SearchRequest) {
  const response = await service.post("/search-point", searchRequest);
  return response.data as SearchResponse;
}

/**
 * ポイント登録APIをコールします。
 *
 * @param registerRequest 検索のリクエストボディ
 * @return CommonResponse
 */
export async function register(registerRequest: RegisterRequest) {
  const response = await service.post("/regist-point", registerRequest);
  return response.data as CommonResponse;
}
