import { Component, Prop, Vue } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIDataTable from "@/components/UIDataTable.vue";
import { DEFAULT_TABLE_OPTIONS, TableOptions } from "@/api/request";
import PointSearch, { ListItem } from "@/store/point/search";
import Flash, { ErrorAlert } from "@/store/common/flash";
import WindowSizeChecker from "@/utils/window-size-checker";
import { SearchRequest } from "@/api/point/request";
import Loading from "@/store/loading";

@Component({
  components: {
    UlContentHeader,
    UlBreadcrumbs,
    UIDataTable
  }
})
export default class list extends Vue {
  @Prop({ type: String, required: true })
  shopId!: string;

  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "ポイント";
  headingSub = "Points";
  breadCrumbs = [
    { text: "アピール", disabled: true },
    { text: "ポイント", disabled: true },
    {
      text: "店舗一覧",
      disabled: false,
      to: { name: "points" }
    },
    { text: "ポイントカード一覧", disabled: true }
  ];

  // テーブルヘッダ
  tableHeaders = [
    { text: "会員ID", value: "id" },
    { text: "氏名", value: "name" },
    { text: "性別", value: "gender" },
    { text: "生年月日", value: "birthday" },
    { text: "現在のポイント", value: "point" },
    {
      label: "編集",
      text: "",
      value: "edit",
      sortable: false
    }
  ];

  // ------------

  // ------------
  // 変動値
  // ------------
  // 検索入力オプション
  inputOptions = {
    shopId: Number(this.shopId),
    userId: null as number | null,
    userName: null as string | null
  } as SearchRequest;

  // 検索テーブルオプション
  tableOptions = DEFAULT_TABLE_OPTIONS;

  // ------------

  get loading() {
    return Loading.isLoading;
  }

  /**
   * テーブルに表示するアイテムリスト
   */
  get tableItems() {
    return PointSearch.getItems;
  }

  /**
   * 総件数
   */
  get totalCount() {
    return PointSearch.getTotalCount;
  }

  get isShowSetting() {
    return PointSearch.getWriteFlg;
  }

  get isTabletAndPC() {
    return WindowSizeChecker.isTabletAndPC(this);
  }

  get shopName() {
    return this.$route.query.shopName || "";
  }

  /**
   * createdライフサイクルフック（UIDataTableコンポーネントに渡す）
   */
  async created() {
    await PointSearch.restore(this.inputOptions as SearchRequest);
    const request = PointSearch.getSearchRequest;
    // 検索入力オプション
    request.userId && (this.inputOptions.userId = request.userId);
    request.userName && (this.inputOptions.userName = request.userName);

    // 検索テーブルオプション
    request.page && (this.tableOptions = request);
    await this.search();
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await PointSearch.clearResponse();
    DEFAULT_TABLE_OPTIONS.userId && (delete DEFAULT_TABLE_OPTIONS.userId);
    DEFAULT_TABLE_OPTIONS.userName && (delete DEFAULT_TABLE_OPTIONS.userName);
  }

  /**
   * ページング変更コールバック
   *
   * @param tableOptions TableOptions
   */
  async tableChangeCallback(tableOptions: TableOptions) {
    // 子コンポーネントへpagingOptionsの変更が通知される
    this.tableOptions = tableOptions;
    await this.search();
  }

  /**
   * 検索ボタンがクリックされた際のコールバック
   */
  async searchClickCallback() {
    this.tableOptions.page = 1;//検索後、1ページ目へ移動
    await Flash.clear();
    await this.search();
  }

  /**
   * テーブル行の編集ボタンがクリックされた際のコールバック
   */
  editClickCallback(item: ListItem) {
    this.$router.push({
      name: "points-edit",
      params: { id: String(item.id) },
      query: {
        shopId: this.shopId,
        shopName: this.shopName
      }
    });
  }

  /**
   * 検索処理
   */
  async search() {
    await PointSearch.search(this.createRequest());
    if (!PointSearch.isSuccess) {
      await Flash.setErrorNow({
        message: PointSearch.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
  }

  goToSetting() {
    this.$router
      .push({
        name: "points-setting",
        params: { shopId: String(this.shopId) },
        query: { shopName: this.shopName }
      })
      .then();
  }

  /**
   * dataから検索用のリクエストを作成する
   */
  private createRequest(): SearchRequest {
    const request = this.tableOptions as SearchRequest;
    request.shopId = Number(this.shopId);
    if (this.inputOptions.userId) {
      request.userId = this.inputOptions.userId;
    } else {
      delete request.userId;
    }

    if (this.inputOptions.userName) {
      request.userName = this.inputOptions.userName;
    } else {
      delete request.userName;
    }

    return request;
  }
}
